import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { colors, breakpoints, Container, H1, LinkButton } from "styles";
import { Logo } from "assets/svgs";
import { Markup } from "interweave";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "hooks";

const CarouselHeader = ({ data }) => {
  const { windowWidth, windowHeight } = useWindowSize();
  const isMobile = windowWidth < 840;
  const slides = useMemo(
    () => [
      {
        id: 1,
        title: `Heat wraps for effective pain relief`,
        content: `
          <p>ThermaCare® clinically proven heat wraps provide powerful, targeted drug free pain relief.</p>
          <br />
          <p>ThermaCare® heat wraps provide long lasting pain relief for lower back, hip, neck, shoulder and wrist associated with:</p>
          <ul>
          <li>Muscle Tension.</li>
          <li>Overexertion.</li>
          <li>Strains and sprains.</li>
          <li>Arthritis.</li>
          </ul>
          <h4>ThermaCare® is suitable for suffers of chronic and occasional pain.</h4>
        `,
        link: "/",
        image: (
          <StaticImage
            src="../../../assets/images/banner-man-on-bike.png"
            alt="Man happy on bike"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
            objectFit="contain"
            objectPosition={isMobile ? "bottom center" : "bottom left"}
          />
        ),
      },
      {
        id: 2,
        title: `Heat wraps for effective pain relief`,
        content: `
          <p>ThermaCare® heat wraps for more targeted pain relief. Clinical trials show that Thermacare heat wraps are more effective than the maximum daily dosage of either Paracetamol or Ibuprofen for long lasting pain relief.</p><br/>
          <p>ThermaCare® heat wraps provides therapeutic muscle penetrating heat and long lasting pain relief for up to 16 hours. That&apos;s up to 8 hours while you wear the heat wrap plus an additional 8 hours of pain relief after you remove it. </p><br/>
          <p>ThermaCare® heat wraps uses patented heat cell technology for deep penetrating heat.</p>
          <h4>ThermaCare is the pioneer of heat cell technology.</h4>
        `,
        link: "/",
        image: isMobile ? (
          <StaticImage
            src={"../../../assets/images/banner-full-back-pain-mobile.png"}
            alt="Man suffering from lower back pain"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
            objectFit="contain"
            objectPosition="bottom right"
          />
        ) : (
          <StaticImage
            src={"../../../assets/images/banner-full-back-pain.png"}
            alt="Man suffering from lower back pain"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
            objectFit="cover"
            objectPosition="bottom left"
          />
        ),
      },
    ],
    []
  );

  const [page, setPage] = useState(slides[0]);

  const paginate = useCallback(() => {
    page === slides[0] ? setPage(slides[1]) : setPage(slides[0]);
  }, [page, slides]);

  useEffect(() => {
    const timer = setTimeout(() => paginate(), 20000);
    return () => clearTimeout(timer);
  }, [paginate]);

  return (
    <StyledHeader
      $bg={colors.red}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <GatsbyImage image={getImage(data.topFlame)} alt="Background" />
      <GatsbyImage image={getImage(data.bottomFlame)} alt="Background" />
      <Carousel>
        <Container>
          <TextContent>
            <Logo />
            <H1 $color="white">Heat wraps for effective pain relief</H1>
            <AnimatePresence exitBeforeEnter>
              <Animated
                key={`${page.id}-text`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Markup content={page.content} tagName="div" />
              </Animated>
            </AnimatePresence>
            <LinkButton to="/products" $bg={colors.darkRed}>
              View Products
            </LinkButton>
          </TextContent>
          <AnimatePresence exitBeforeEnter>
            <HeaderImage
              key={`${page.id}-image`}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
            >
              {page.image}
            </HeaderImage>
          </AnimatePresence>
        </Container>
      </Carousel>
    </StyledHeader>
  );
};

const Animated = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const HeaderImage = styled(motion.div)`
  bottom: 0;
  height: 22rem;
  max-height: 100%;
  position: absolute;
  right: -20%;
  width: 100%;
  z-index: 2;
  .gatsby-image-wrapper {
    height: 100% !important;
    overflow: visible;
    width: 100% !important;
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
  ${breakpoints.sm} {
    height: 40rem;
    right: -25%;
    width: 100%;
  }
  ${breakpoints.md} {
    height: 100rem;
    right: 0;
    width: 100%;
  }
  ${breakpoints.lg} {
    height: 100%;
    right: -10%;
    width: 50%;
  }
  ${breakpoints.xl} {
    height: 100%;
    right: -35%;
    width: 80%;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-bottom: 12rem;
  max-width: 600px;
  padding: 3rem 0;
  position: relative;
  width: 100%;
  z-index: 3;
  svg {
    height: auto;
    margin: 2rem 0;
    width: 280px;
    path {
      fill: white;
    }
  }
  ${H1} {
    margin-bottom: 3rem;
  }
  ul,
  p {
    color: white;
    font-family: "Gotham";
    font-size: 0.925rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 0.4em;
  }
  h4 {
    color: white;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1rem;
  }
  ul {
    margin: 1.5rem;
  }
  ${breakpoints.sm} {
    grid-column: 1/7;
    margin-bottom: 20rem;
    svg {
      width: 400px;
    }
    p,
    ul {
      font-size: 1rem;
    }
  }
  ${breakpoints.md} {
    grid-column: 1/7;
    margin-bottom: 20rem;
    svg {
      width: 400px;
    }
    p,
    ul {
      font-size: 1rem;
    }
    h4 {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 2rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/8;
    margin-bottom: 0;

    svg {
      width: 400px;
    }
    p,
    ul {
      font-size: 1rem;
    }
  }
`;

const BackgroundSVG = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  &:after {
    background: linear-gradient(rgba(157, 44, 41, 0.4), rgba(157, 44, 41));
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  svg {
    height: auto;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 200%;
    z-index: 1;
    path {
      stroke: #f6921e;
    }
  }
  ${breakpoints.md} {
    svg {
      width: 100%;
    }
  }
  ${breakpoints.lg} {
    svg {
      width: 100%;
    }
  }
`;

const Carousel = styled(motion.div)`
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
`;

const StyledHeader = styled(motion.header)`
  background: ${({ $bg }) => $bg};
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    &:first-child {
      top: 0;
      right: 0;
    }
    &:nth-child(2) {
      bottom: 0;
      right: 0;
    }
  }
`;

export default CarouselHeader;
