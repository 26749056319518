import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

const Modal = ({ isModalOpen, closeModal, darkBackground, children }) => {
  useEffect(() => {
    const bind = (e) => {
      if (e.keyCode !== 27) {
        return;
      }
      if (
        document.activeElement &&
        ["INPUT", "SELECT"].includes(document.activeElement.tagName)
      ) {
        return;
      }
      closeModal();
    };

    document.addEventListener("keyup", bind);
    return () => document.removeEventListener("keyup", bind);
  }, [isModalOpen]);
  if (typeof window !== `undefined`) {
    return ReactDOM.createPortal(
      <ModalContainer
        darkBackground={darkBackground}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
      >
        {children}
      </ModalContainer>,
      document.body
    );
  } else {
    return null;
  }
};

const ModalContainer = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  background: ${({ darkBackground }) =>
    darkBackground ? "rgba(157, 44, 41, 0.7)" : "rgba(255, 255, 255, 0.25)"};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
`;

export default Modal;
